.heading {
    /* font-size: 20px; */
    font-size: 2vw;
    color: var(--font-color);
}

.location{
    a {
        font-weight:lighter;
        text-decoration: none;
        color: var(--font-color);
    }

    a:hover{
        font-weight:normal;
    }
    
    /* font-size: 15px; */
    font-size: 1.5vw;
    text-align: start;
}

.prefix{
    text-align: left;
    padding-left: 10px;
}

.name {
    color: rgb(255, 122, 85);
}

.job-role {
    /* font-size: 100px; */
    font-size: 9vw;
    color:rgb(146, 179, 255)
}