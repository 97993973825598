.left-column{
    width: 50vw;
    /* height: 100vh; */

    /* linear-gradient(to right, white, black) */
    background: 
        /* url('./../../../public/assets/pexels-italo-melo-881954-2379004.jpg')  no-repeat center/cover, */
        /* linear-gradient(to top,  rgba(18, 90, 214, 0.361) 25%,  rgba(0, 0, 0, 0)), */
        url('./../../../public/data/profile2-removed-bg.png')  no-repeat center/cover;
    background-blend-mode: multiply; /* Blend the gradient with the image */

      /* Apply gradient transparency */
      -webkit-mask-image: linear-gradient(to right, transparent, black 25%, black 75%, transparent);
      mask-image: linear-gradient(to right, transparent, black 25%, black 75%, transparent);

      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;

      -webkit-mask-size: cover;
      mask-size: cover;
}

/* Mobile Screen */
@media screen and (max-width: 768px){
    .left-column{
        /* Apply gradient transparency */
        -webkit-mask-image: linear-gradient(to right,  black 25%, black 75%, transparent);
        mask-image: linear-gradient(to right,  black 25%, black 75%, transparent);

        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;

        -webkit-mask-size: cover;
        mask-size: cover;
    }
}