:root {
    --transparent: rgba(255, 255, 255, 0);
    --font-color: antiquewhite;
    --font-theme: 'Poppins', serif;
    --themedark: rgb(24, 24, 56);
    --themedarker-btn: rgb(0, 0, 48);
    --themelight: rgb(146, 179, 255);
    --skillcard: rgb(209, 223, 255);
    --skillcard-hover: rgb(146, 179, 255);
    --themedark-warning: rgb(228, 107, 107);
    --themelight-warning: rgb(255, 199, 199);

    /* For background animation */
    --gradient-1: rgb(41, 41, 105);
    --gradient-2: rgb(24, 24, 56);
}

body {
    font-family: var(--font-theme);
    font-size: smaller;
    margin: 0px;
    background-color: var(--themedark);
    cursor:default;

    
    /* margin-left: 9vw;
    margin-right: 9vw; */

    /* background-image: url('./../public/assets/pexels-italo-melo-881954-2379004.jpg');
    background-size: cover;
    background-size:40%;
    background-repeat: no-repeat; */
    /* background-size: contain; */
}

/* Mobile Screen */
@media screen and (max-width: 768px){
    .page-container {
        padding: 0% !important;
    }
}

/* Full background animation */
.background {
    /* position: fixed; */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background: linear-gradient(-45deg, 
                var(--gradient-1), var(--gradient-2), 
                var(--gradient-1), var(--gradient-2),
                var(--gradient-1), var(--gradient-2));
    background-size: 400% 400%;
    animation: gradientAnimation 15s ease infinite;
    z-index: -1;
  }

  @keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

.page-container {
    padding-left: 9vw;
    padding-right: 9vw;
}