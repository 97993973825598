.custom-btn {
    /* background-color: black; */
    background-color: var(--themedarker-btn);
    /* color: white; */
    color: var(--themelight);

    /* border: 1px solid black; */
    border: 1px solid var(--themedarker-btn);
    border-radius: 5px;
    cursor: pointer;
    padding: 10px;
    margin: 5px;
    width:fit-content;
    text-decoration: none; /* Remove anchor decorations */
    text-align: center;
    /* a {
        text-decoration: none;
        color: var(--themelight);
        font: inherit;
        text-align: center;
    } */
}

.custom-btn:hover{
    /* background-color: white; */
    background-color: var(--themelight);
    /* color: black; */
    color: var(--themedarker-btn);
    /* a {
        color: var(--themedarker-btn);
    } */
}

.btn{
    border: 1px solid var(--themelight);
}

.disabled-btn{
    cursor:not-allowed;
    background-color: var(--themedark);
    color: var(--themelight);
}

.disabled-btn:hover{
    background-color: var(--themedark);
    color: var(--themelight);
}

.icon-element{
    vertical-align: middle;
    padding-bottom: 0.4vw;
    padding-right: 1vw;
}

.emoji-element{
    padding-right: 1vw;
}

@keyframes blink-animation {
    0%, 50% {
      color: red;
    }
    51%, 100% {
      color: white;
    }
  }

.recording-animation{
    text-align: center;
    animation: blink-animation 1s infinite;
}



