.personal-projects-container{
    /* background-color: var(--themedark); */
    height: 100vh;
    text-align: center;
}

.projects-title{
    padding-top: 1vh;
    font-size:large;
    color: var(--font-color);
}

.projects {
    display: inline-flex;
    color: var(--themelight);
}

/* Mobile Screen */
@media screen and (max-width: 474px){
    .projects {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
}

.divider-band{
    background-color: var(--themelight);
    color: var(--themelight);
    margin-left: 2vw;
    margin-right: 2vw;
    border-radius: 10px;
}